<template>
  <div class="mb-3">
    <div class="col-sm-12 job-candidate-status border-light">
      <h5 class="text-primary text-center mt-3">Candidates Status</h5>
      <CRow class="mt-3">
        <CCol class="mb-3" md="3">
          <ShortlistCount
            :activeJobs="getActiveJobCount"
            :totalCount="getTotalCount"
            :expiryJobCount="getExpiryJobCount"
            :status="filters.status"
          />
        </CCol>
        <CCol md="3">
          <CRow class="row pb-3 adjust-filter-label">
            <label class="col-lg-12 col-md-12">Jobs</label>
            <div class="col-lg-12 col-md-12 col-sm-12 pr-1">
              <Select
                name="job"
                :value="filters.job"
                @input="handleChangeSelect"
                :options="options && options['job'] ? options['job'] : []"
                :taggable="false"
                :multiple="false"
                :clearable="false"
              />
            </div>
          </CRow>
        </CCol>
        <CCol md="3" lg="2">
          <CRow class="row pb-3 adjust-filter-label">
            <label class="col-lg-12 col-md-12">Status</label>
            <div class="col-lg-12 col-md-12 col-sm-12 pr-1">
              <Select
                name="status"
                :value="filters.status"
                @input="handleChangeSelect"
                :options="options && options['status'] ? options['status'] : []"
                :taggable="false"
                :multiple="false"
                :clearable="false"
              />
            </div>
          </CRow>
        </CCol>
        <CCol md="3" lg="2">
          <CRow class="row mb-3 adjust-filter-label">
            <label class="col-lg-12 col-md-12">Candidate Type</label>
            <div class="col-lg-12 col-md-12 col-sm-12 pr-1">
              <Select
                name="candidateType"
                :value="filters.candidateType"
                @input="handleChangeSelect"
                :options="
                  options && options['candidateType']
                    ? options['candidateType']
                    : []
                "
                :taggable="false"
                :multiple="false"
                :clearable="true"
              />
            </div>
          </CRow>
        </CCol>
        <CCol md="3" lg="2">
          <CRow class="row mb-3">
            <label class="col-lg-12 col-md-12"
              >Candidates Linked to<br />
              Expiring Jobs</label
            >
            <div class="col-lg-12 col-md-12 col-sm-12 pr-1">
              <Select
                name="expiryBy"
                :value="filters.expiryBy"
                @input="handleChangeSelect"
                :options="
                  options && options['expiryBy'] ? options['expiryBy'] : []
                "
                :taggable="false"
                :multiple="false"
                :clearable="true"
              />
            </div>
          </CRow>
        </CCol>
      </CRow>
      <CRow>
        <CCol md="3"> </CCol>
        <CCol>
          <CRow class="ml-1">
            <h5>Search Results: {{ filteredStatusCandidatesCount }}</h5>
          </CRow>
        </CCol>
      </CRow>
      <hr class="mb-4" />
      <CRow class="mb-3">
        <CCol v-for="(item, index) in getCandidates" :key="index" sm="6" md="3">
          <div v-if="isFetchingStatusCandidates" class="skeleton-card"></div>
          <candidate-list-for-shortlist-process
            v-else
            :item="item.candidate"
            :job="item.job"
            :doh_candidate_status="item.doh_candidate_status"
            :application_status="item.application_status"
            :showJobExpiry="!disableExpiringIn"
          />
        </CCol>
      </CRow>
      <div
        class="d-flex justify-content-center p-4"
        v-if="
          !getFilteredStatusCandidates.length && !isFetchingStatusCandidates
        "
      >
        <h1>No Data found!</h1>
      </div>
      <div
        class="d-flex justify-content-center"
        v-if="
          getFilteredStatusCandidates.length &&
          jobCandidatesPagePagination.noMoreCandidates
        "
      >
        <span class="btn-pill btn-primary p-2"
          >No more records to display!</span
        >
      </div>
      <CRow
        class="d-flex justify-content-around align-items-center"
        style="height: 200px"
        v-if="isFetchingStatusCandidates"
      >
        <CCol>
          <div class="has-loading-overlay">
            <LoadingOverlay />
          </div>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import Select from "../components/reusable/Fields/Select.vue";
import ShortlistCount from "@/components/ShortlistCount";
import {
  getFilterQueryStringWithoutArray,
  deepClone,
} from "@/helpers/helper.js";
import CandidateListForShortlistProcess from "@/containers/JobCandidateStatus/CandidateListForShortlistProcess";

export default {
  components: { Select, ShortlistCount, CandidateListForShortlistProcess },
  name: "ShortlistedCandidates",
  data: () => ({
    filters: {},
    filtersCode: {},
  }),
  computed: {
    ...mapGetters([
      "getCustomerId",
      "getFilteredStatusCandidates",
      "getShortliststatus",
      "getInterviewstatus",
      "getRecruitstatus",
      "getFacilityConfig",
      "allFiltersData",
      "getOrgID",
      "isFetchingStatusCandidates",
      "jobCandidatesPagePagination",
      "getAllJobsFilterList",
      "filteredStatusCandidatesCount",
      "getShortlistStatusCount",
      "getFacilityMaxCountConfig",
      "getActiveJobCount",
      "getExpiryJobCount",
      "getOrgIDFromAccessToken",
    ]),
    getCandidates() {
      if (
        this.filters?.status?.code != "applied" &&
        this.filters?.status?.code != "withdrawn"
      ) {
        return (
          this.getFilteredStatusCandidates?.length &&
          this.getFilteredStatusCandidates?.map((val) => {
            return {
              candidate: val.candidate,
              job: {
                job_id: val?.job_id,
                job_title: val?.job_title,
              },
              doh_candidate_status: val,
              application_status: null,
            };
          })
        );
      } else {
        return (
          this.getFilteredStatusCandidates?.length &&
          this.getFilteredStatusCandidates?.map((val) => {
            return {
              candidate: val.candidate,
              job: {
                job_id: val?.job_id,
                job_title: val?.job?.job_title,
              },
              doh_candidate_status: val.doh_candidate_status,
              application_status: val,
            };
          })
        );
      }
    },
    options() {
      return {
        job: [
          {
            label: "All Jobs",
            code: "all",
          },
          ...this.getAllJobsFilterList,
        ],
        status: [
          { code: "applied", label: "Applied" },
          { code: 34, label: "Shortlist" },
          { code: 36, label: "Interview" },
          { code: 37, label: "Recruit" },
          { code: "withdrawn", label: "Withdrawn" },
        ],
        expiryBy: [
          { code: 1, label: "Expiry in 24 hours" },
          { code: 3, label: "Expiry in 3 days" },
          { code: 7, label: "Expiry in 7 days" },
        ],
        candidateType: this.allFiltersData.candidateType || [],
      };
    },
    getTotalCount() {
      return this.filters?.status?.label
        ? this.getShortlistStatusCount[
            this.filters?.status?.label?.toLowerCase()
          ]
        : 0;
    },
    disableExpiringIn() {
      return this.filters?.status?.code == 34 ||
        this.filters?.status?.code == 36
        ? false
        : true;
    },
  },
  beforeDestroy() {
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
  methods: {
    ...mapActions([
      "setCurrentNavigationCandidatesPage",
      "fetchFilteredStatusCandidates",
      "getallshortliststatus",
      "fetchFacilityConfig",
      "genericCandidateType",
      "fetchAllJobsFilterList",
      "fetchActiveAndExpiryJobsCount",
    ]),
    handleChangeSelect(name, value) {
      Vue.set(this.filters, name, code);
      let code = value ? value.id || value.code || value : null;
      let newChange = deepClone(this.filtersCode);
      Vue.set(newChange, name, code);
      if (name == "status") {
        newChange.candidateType = null;
        newChange.expiryBy = null;
      }
      this.updateUrl(newChange);
    },
    updateUrl(newChange) {
      for (const [key, value] of Object.entries(newChange)) {
        if ((key == "job" && value == "all") || !value) {
          delete newChange[key];
        }
      }
      this.$router.push(
        `/candidates-status?${getFilterQueryStringWithoutArray(newChange)}`
      );
    },
    onBodyScroll(e) {
      if (
        this.isFetchingStatusCandidates ||
        this.jobCandidatesPagePagination.noMoreCandidates
      )
        return;
      if (
        e.target.scrollHeight - e.target.scrollTop <=
        e.target.clientHeight + 1
      ) {
        this.fetchFilteredStatusCandidates({ pagination: true });
      }
    },
    fetchCandidates() {
      let query = this.$router.currentRoute.query;
      let appendAction = [];
      this.filtersCode = {};
      for (const [key, value] of Object.entries(query)) {
        this.filtersCode[key] = value;
      }
      Promise.all(appendAction).then((response) => {
        this.filters = { job: { label: "All Jobs", code: "all" } };
        for (const [key, value] of Object.entries(query)) {
          this.filters[key] = this.options[key].filter(
            (val) => value == val.code
          )[0];
        }
      });
      this.fetchFilteredStatusCandidates({ pagination: false });
    },
  },
  watch: {
    "$route.query": function () {
      this.fetchCandidates();
    },
  },
  mounted() {
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
    //For setting getNextCandidateId and getPreviousCandidateId function according to current Page
    let page = {
      currentPage: this.$router.currentRoute.name,
    };
    this.setCurrentNavigationCandidatesPage(page);
  },
  async created() {
    let appendAction = [];
    if (!this.getFacilityConfig.length) {
      appendAction = [
        this.fetchFacilityConfig({
          customer_id: this.getCustomerId,
          organisation_id: this.getOrgIDFromAccessToken,
        }),
      ];
    }
    if (!this.allFiltersData?.candidateType?.length) {
      appendAction = [...appendAction, this.genericCandidateType()];
    }
    if (
      this.getShortliststatus.length == undefined ||
      this.getInterviewstatus.length == undefined ||
      this.getRecruitstatus.length == undefined ||
      !this.getFacilityConfig.length
    ) {
      appendAction = [...appendAction, this.getallshortliststatus()];
    }
    await this.fetchAllJobsFilterList();
    this.fetchActiveAndExpiryJobsCount();
    Promise.all([appendAction]).then((res) => {
      this.fetchCandidates();
    });
  },
};
</script>

<style lang="scss" scoped>
.job-candidate-status {
  background-color: white;
  margin: auto;
  min-height: 325px;
}
.filterheight {
  height: 80px;
}
@media all and (max-width: 480px) {
  .col-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .filterheight {
    flex: 0 0 98.33333%;
    max-width: 98.33333%;
  }
  .v-select {
    width: 250px;
  }
}
@media all and (max-width: 768px) {
  .hidden-label {
    display: none;
  }
}
@media all and (min-width: 1200px) {
  .adjust-filter-label {
    margin-top: 21px;
  }
}
</style>
