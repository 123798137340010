<template>
  <div class="candidate-count pr-1">
    <div class="main_div bg-primary text-white border-light">
      {{
        getStatus == "Applied" || getStatus == "Withdrawn"
          ? `${getStatus} Candidates`
          : "Candidates in Process"
      }}
      <div class="sub_div">{{ totalCount && totalCount.toLocaleString() }}</div>
    </div>
    <div class="main_div bg-primary text-white border-light">
      Active Jobs
      <div class="sub_div">
        <router-link :to="jobListURL.activeURL">{{
          activeJobs && activeJobs.toLocaleString()
        }}</router-link>
      </div>
    </div>
    <div class="main_div bg-primary text-white border-light">
      Jobs Expiring<br />
      (in 24 Hours)
      <div class="sub_div">
        <router-link :to="jobListURL.expiryURL">{{
          expiryJobCount && expiryJobCount.toLocaleString()
        }}</router-link>
        <!-- <CButton class="btn-link p-0 border-0">{{ expiryJobCount && expiryJobCount.toLocaleString() }}</CButton> -->
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { getFilterQueryString } from "@/helpers/helper";
export default {
  props: ["activeJobs", "totalCount", "expiryJobCount", "status"],
  computed: {
    getStatus() {
      return this.status?.label;
    },
    jobListURL() {
      const published = [29];
      const expiryFromTime = moment().format("YYYY-MM-DD");
      const expiryToTime = moment()
        .add(1, "days")
        .format("YYYY-MM-DD");
      const expiryURL = `job-list?${getFilterQueryString({
        published,
        expiryFromTime,
        expiryToTime,
      })}`;
      const activeURL = `job-list?${getFilterQueryString({ published })}`;
      return {
        expiryURL,
        activeURL,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.candidate-count {
  display: flex;
  width: 100%;
  .main_div {
    text-align: center;
    position: relative;
    width: 100%;
    min-height: 85px;
    color: #3c4b64;
    padding: 5px;
    margin: 2px;
    .sub_div {
      background-color: $white;
      border: 1px solid lightgray;
      color: $color-black;
      font-weight: 700;
      position: absolute;
      bottom: 0px;
      margin: 10px 0px 5px;
      width: 90%;
    }
  }
}
.danger {
  color: $red !important;
}

@media all and (max-width: 480px) {
  .candidate-count {
    width: 100%;
  }
}
@media all and (max-width: 1450px) and (min-width: 1200px) {
  .candidate-count {
    min-height: 125px;
  }
}
</style>
