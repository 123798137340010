var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.item)?_c('CCard',{staticClass:"candidate-card border-primary",staticStyle:{"min-height":"425px"}},[_c('div',{staticClass:"d-flex"},[_c('CandidateInfo',{attrs:{"item":_vm.candidateInfoData,"job":_vm.job}})],1),_c('CCardBody',{staticClass:"content-block"},[(
        !(_vm.doh_candidate_status && _vm.doh_candidate_status.status_id) &&
        _vm.isCustomer &&
        _vm.application_status &&
        !_vm.shortlistDisabled
      )?_c('div',{staticClass:"justify-content-center mb-2"},[_c('CRow',[_c('CCol',{staticClass:"btncol"},[_c('CButton',{staticClass:"flex-2 mb-1 btnmargin rounded-0",attrs:{"color":"danger"},on:{"click":function($event){return _vm.confirmationForReject()}}},[_vm._v("Disapprove")])],1)],1)],1):_vm._e(),(_vm.doh_candidate_status && _vm.isCustomer)?_c('div',{staticClass:"justify-content-center mb-2"},[_c('CRow',[_c('CCol',{staticClass:"btncol"},[(
              _vm.doh_candidate_status.status_id === _vm.getShortliststatus.status_id
            )?_c('CButton',{staticClass:"flex-2 mb-1 btnmargin rounded-0",attrs:{"color":"primary"},on:{"click":function($event){return _vm.confirmationPopupwithReason(_vm.getInterviewstatus.status)}}},[_vm._v("Interview")]):_vm._e(),(
              _vm.doh_candidate_status.status_id === _vm.getInterviewstatus.status_id
            )?_c('CButton',{staticClass:"flex-2 mb-1 btnmargin rounded-0",attrs:{"color":"primary"},on:{"click":function($event){return _vm.confirmationPopupwithReason(_vm.getRecruitstatus.status)}}},[_vm._v("Recruit")]):_vm._e(),(
              _vm.doh_candidate_status.status_id ===
                _vm.getShortliststatus.status_id ||
              _vm.doh_candidate_status.status_id ===
                _vm.getInterviewstatus.status_id ||
              _vm.doh_candidate_status.status_id === _vm.getRecruitstatus.status_id
            )?_c('CButton',{staticClass:"flex-2 mb-1 btnmargin rounded-0",attrs:{"color":"danger"},on:{"click":function($event){return _vm.confirmationPopupwithReason(_vm.getReleasestatus.status)}}},[_vm._v("Release")]):_vm._e()],1)],1),(_vm.showJobExpiry)?_c('CRow',[_c('CCol',{staticClass:"btncol"},[(_vm.expirydays == 0 || _vm.expirydays == 1)?_c('span',{staticClass:"px-1 fs-12 lblspan"},[_vm._v("Job Expiring in: "+_vm._s(_vm.expirydays == 0 ? "Today" : "1 day")+". This candidate will be released so please progress or update your job expiry date.")]):_vm._e(),(_vm.expirydays > 1)?_c('span',{staticClass:"px-1 fs-12 lblspan"},[_vm._v("Job Expiring in: "+_vm._s(_vm.expirydays)+" days. This candidate will be released so please progress or update your job expiry date.")]):_vm._e()])],1):_vm._e()],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column justify-content-between"},[_c('CRow',[_c('CCol',{staticClass:"lable",attrs:{"col":"5"}},[_vm._v(" "+_vm._s(_vm.isNurse ? `Main Speciality` : `Speciality`)+" ")]),(_vm.isWrapped(_vm.candidateInfoData.speciality))?_c('CCol',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: _vm.candidateInfoData.speciality,
          }),expression:"{\n            content: candidateInfoData.speciality,\n          }"}],staticClass:"value text-truncate",attrs:{"col":"7"}},[_vm._v(" "+_vm._s(_vm.candidateInfoData.speciality || "--")+" ")]):_c('CCol',{staticClass:"value text-truncate",attrs:{"col":"7"}},[_vm._v(" "+_vm._s(_vm.candidateInfoData.speciality || "--")+" ")])],1),(_vm.isNurse)?_c('CRow',[_c('CCol',{staticClass:"lable",attrs:{"col":"5"}},[_vm._v(" Other Expertise ")]),(_vm.isWrapped(_vm.otherSpecialities))?_c('CCol',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: _vm.otherSpecialities,
          }),expression:"{\n            content: otherSpecialities,\n          }"}],staticClass:"value text-truncate",attrs:{"col":"7"}},[_vm._v(" "+_vm._s(_vm.otherSpecialities)+" ")]):_c('CCol',{staticClass:"value text-truncate",attrs:{"col":"7"}},[_vm._v(" "+_vm._s(_vm.otherSpecialities)+" ")])],1):_c('CRow',[_c('CCol',{staticClass:"lable",attrs:{"col":"5"}},[_vm._v(" "+_vm._s(_vm.isAHP ? `Sub Type / License` : `Sub Speciality`))]),(_vm.isWrapped(_vm.candidateInfoData.sub_speciality))?_c('CCol',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: _vm.candidateInfoData.sub_speciality,
          }),expression:"{\n            content: candidateInfoData.sub_speciality,\n          }"}],staticClass:"value text-truncate",attrs:{"col":"7"}},[_vm._v(" "+_vm._s(_vm.candidateInfoData.sub_speciality || "--")+" ")]):_c('CCol',{staticClass:"value text-truncate",attrs:{"col":"7"}},[_vm._v(" "+_vm._s(_vm.candidateInfoData.sub_speciality || "--")+" ")])],1),_c('CRow',[_c('CCol',{staticClass:"lable",attrs:{"col":"5"}},[_vm._v(" Qualification ")]),(_vm.isWrapped(_vm.qualificationName))?_c('CCol',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: _vm.qualificationName,
          }),expression:"{\n            content: qualificationName,\n          }"}],staticClass:"value text-truncate",attrs:{"col":"7"}},[_vm._v(" "+_vm._s(_vm.qualificationName || "--")+" ")]):_c('CCol',{staticClass:"value text-truncate",attrs:{"col":"7"}},[_vm._v(" "+_vm._s(_vm.qualificationName || "--")+" ")])],1),_c('CRow',[_c('CCol',{staticClass:"lable",attrs:{"col":"5"}},[_vm._v(" Country Obtained")]),_c('CCol',{staticClass:"value",attrs:{"col":"7"}},[_vm._v(" "+_vm._s(_vm.qualificationCountry || "--")+" ")])],1),_c('CRow',[_c('CCol',{staticClass:"lable",attrs:{"col":"5"}},[_vm._v(" Date Obtained ")]),_c('CCol',{staticClass:"value",attrs:{"col":"7"}},[_vm._v(" "+_vm._s(_vm.qualificationDate || "--")+" ")])],1),(!_vm.isAHP)?_c('CRow',[_c('CCol',{staticClass:"lable",attrs:{"col":"5"}},[_vm._v("Level")]),(_vm.isWrapped(_vm.candidateInfoData.candidate_level))?_c('CCol',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: _vm.candidateInfoData.candidate_level,
          }),expression:"{\n            content: candidateInfoData.candidate_level,\n          }"}],staticClass:"value text-truncate",attrs:{"col":"7"}},[_vm._v(" "+_vm._s(_vm.candidateInfoData.candidate_level || "--")+" ")]):_c('CCol',{staticClass:"value text-truncate",attrs:{"col":"7"}},[_vm._v(" "+_vm._s(_vm.candidateInfoData.candidate_level || "--")+" ")])],1):_vm._e(),_c('CRow',[_c('CCol',{staticClass:"lable",attrs:{"col":"5"}},[_vm._v("Local License")]),_c('CCol',{staticClass:"value",attrs:{"col":"7"}},[_vm._v(" "+_vm._s(_vm.localLicense || "N/A")+" ")])],1)],1)]),_c('div',{staticClass:"d-flex justify-content-center pb-2"},[_c('CButton',{staticClass:"mx-2 action-btn",attrs:{"color":"primary","variant":"outline","disabled":!_vm.cvDocument},on:{"click":function($event){return _vm.previewCV(_vm.cvDocument)}}},[_vm._v(" CV/Resume")]),(
        !(_vm.doh_candidate_status && _vm.doh_candidate_status.status_id) &&
        _vm.isCustomer
      )?_c('CButton',{staticClass:"mx-2 action-btn",attrs:{"color":"primary","variant":"outline","disabled":_vm.shortlistDisabled},on:{"click":function($event){return _vm.confirmationPopupwithReason(_vm.getShortliststatus.status)}}},[_vm._v(" Shortlist")]):_vm._e(),_c('CButton',{staticClass:"mx-2 action-btn",attrs:{"color":"primary","variant":"outline"},on:{"click":function($event){return _vm.navigateToCandidate(_vm.item)}}},[_vm._v(" More Details")])],1),_c('PreviewModal',{attrs:{"modalTitle":_vm.previewModal.modalTitle,"modalColor":_vm.previewModal.modalColor,"isShowPopup":_vm.previewModal.isShowPopup,"buttons":_vm.previewModal.buttons,"modalCallBack":_vm.modalCallBack,"isPreview":_vm.previewModal.isPreview,"iframe":_vm.iframe,"size":_vm.previewModal.size,"closeOnBackdrop":_vm.previewModal.closeOnBackdrop}}),_c('Modal',{attrs:{"modalTitle":_vm.noCVModal.modalTitle,"modalColor":_vm.noCVModal.modalColor,"modalContent":_vm.noCVModal.modalContent,"isShowPopup":_vm.noCVModal.isShowPopup,"buttons":_vm.noCVModal.buttons,"modalCallBack":_vm.noCVModalCallback,"closeOnBackdrop":false,"stepperModal":_vm.noCVModal.stepperModal}}),(_vm.popupModal.isShowPopup)?_c('PopupModal',{attrs:{"modalTitle":_vm.popupModal.modalTitle,"modalColor":_vm.popupModal.modalColor,"modalContent":_vm.popupModal.modalContent,"modalLabel":_vm.popupModal.modalLabel,"isShowPopup":_vm.popupModal.isShowPopup,"popupModalCallBack":_vm.popupModalCallBack,"candidateItem":_vm.popupModal.candidateItem,"modalJob":_vm.popupModal.modalJob,"modalReasonType":_vm.popupModal.modalTitle,"isCommentRequired":false,"size":`lg`}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }