<template>
  <CCard
    v-if="item"
    class="candidate-card border-primary"
    style="min-height: 425px"
  >
    <div class="d-flex">
      <CandidateInfo :item="candidateInfoData" :job="job" />
    </div>
    <CCardBody class="content-block">
      <div
        v-if="
          !(doh_candidate_status && doh_candidate_status.status_id) &&
          isCustomer &&
          application_status &&
          !shortlistDisabled
        "
        class="justify-content-center mb-2"
      >
        <CRow>
          <CCol class="btncol">
            <CButton
              color="danger"
              class="flex-2 mb-1 btnmargin rounded-0"
              @click="confirmationForReject()"
              >Disapprove</CButton
            ></CCol
          >
        </CRow>
      </div>
      <div
        v-if="doh_candidate_status && isCustomer"
        class="justify-content-center mb-2"
      >
        <CRow>
          <CCol class="btncol">
            <CButton
              v-if="
                doh_candidate_status.status_id === getShortliststatus.status_id
              "
              color="primary"
              class="flex-2 mb-1 btnmargin rounded-0"
              @click="confirmationPopupwithReason(getInterviewstatus.status)"
              >Interview</CButton
            >
            <CButton
              v-if="
                doh_candidate_status.status_id === getInterviewstatus.status_id
              "
              color="primary"
              class="flex-2 mb-1 btnmargin rounded-0"
              @click="confirmationPopupwithReason(getRecruitstatus.status)"
              >Recruit</CButton
            >
            <CButton
              v-if="
                doh_candidate_status.status_id ===
                  getShortliststatus.status_id ||
                doh_candidate_status.status_id ===
                  getInterviewstatus.status_id ||
                doh_candidate_status.status_id === getRecruitstatus.status_id
              "
              color="danger"
              class="flex-2 mb-1 btnmargin rounded-0"
              @click="confirmationPopupwithReason(getReleasestatus.status)"
              >Release</CButton
            >
          </CCol>
        </CRow>
        <CRow v-if="showJobExpiry">
          <CCol class="btncol">
            <span
              class="px-1 fs-12 lblspan"
              v-if="expirydays == 0 || expirydays == 1"
              >Job Expiring in: {{ expirydays == 0 ? "Today" : "1 day" }}. This
              candidate will be released so please progress or update your job
              expiry date.</span
            >
            <span class="px-1 fs-12 lblspan" v-if="expirydays > 1"
              >Job Expiring in: {{ expirydays }} days. This candidate will be
              released so please progress or update your job expiry date.</span
            >
          </CCol>
        </CRow>
      </div>
      <div class="d-flex flex-column justify-content-between">
        <CRow>
          <CCol class="lable" col="5">
            {{ isNurse ? `Main Speciality` : `Speciality` }}
          </CCol>
          <CCol
            v-if="isWrapped(candidateInfoData.speciality)"
            class="value text-truncate"
            col="7"
            v-c-tooltip="{
              content: candidateInfoData.speciality,
            }"
          >
            {{ candidateInfoData.speciality || "--" }}
          </CCol>
          <CCol v-else class="value text-truncate" col="7">
            {{ candidateInfoData.speciality || "--" }}
          </CCol>
        </CRow>

        <CRow v-if="isNurse">
          <CCol class="lable" col="5"> Other Expertise </CCol>
          <CCol
            v-if="isWrapped(otherSpecialities)"
            class="value text-truncate"
            col="7"
            v-c-tooltip="{
              content: otherSpecialities,
            }"
          >
            {{ otherSpecialities }}
          </CCol>
          <CCol v-else class="value text-truncate" col="7">
            {{ otherSpecialities }}
          </CCol>
        </CRow>

        <CRow v-else>
          <CCol class="lable" col="5">
            {{ isAHP ? `Sub Type / License` : `Sub Speciality` }}</CCol
          >
          <CCol
            v-if="isWrapped(candidateInfoData.sub_speciality)"
            class="value text-truncate"
            col="7"
            v-c-tooltip="{
              content: candidateInfoData.sub_speciality,
            }"
          >
            {{ candidateInfoData.sub_speciality || "--" }}
          </CCol>
          <CCol v-else class="value text-truncate" col="7">
            {{ candidateInfoData.sub_speciality || "--" }}
          </CCol>
        </CRow>

        <CRow>
          <CCol class="lable" col="5"> Qualification </CCol>
          <CCol
            v-if="isWrapped(qualificationName)"
            class="value text-truncate"
            col="7"
            v-c-tooltip="{
              content: qualificationName,
            }"
          >
            {{ qualificationName || "--" }}
          </CCol>
          <CCol v-else class="value text-truncate" col="7">
            {{ qualificationName || "--" }}
          </CCol>
        </CRow>

        <CRow>
          <CCol class="lable" col="5"> Country Obtained</CCol>
          <CCol class="value" col="7">
            {{ qualificationCountry || "--" }}
          </CCol>
        </CRow>

        <CRow>
          <CCol class="lable" col="5"> Date Obtained </CCol>
          <CCol class="value" col="7">
            {{ qualificationDate || "--" }}
          </CCol>
        </CRow>
        <CRow v-if="!isAHP">
          <CCol class="lable" col="5">Level</CCol>
          <CCol
            v-if="isWrapped(candidateInfoData.candidate_level)"
            class="value text-truncate"
            col="7"
            v-c-tooltip="{
              content: candidateInfoData.candidate_level,
            }"
          >
            {{ candidateInfoData.candidate_level || "--" }}
          </CCol>
          <CCol v-else class="value text-truncate" col="7">
            {{ candidateInfoData.candidate_level || "--" }}
          </CCol>
        </CRow>
        <CRow>
          <CCol class="lable" col="5">Local License</CCol>
          <CCol class="value" col="7">
            {{ localLicense || "N/A" }}
          </CCol>
        </CRow>
      </div>
    </CCardBody>
    <div class="d-flex justify-content-center pb-2">
      <CButton
        color="primary"
        variant="outline"
        class="mx-2 action-btn"
        @click="previewCV(cvDocument)"
        :disabled="!cvDocument"
      >
        CV/Resume</CButton
      >
      <CButton
        v-if="
          !(doh_candidate_status && doh_candidate_status.status_id) &&
          isCustomer
        "
        color="primary"
        variant="outline"
        class="mx-2 action-btn"
        :disabled="shortlistDisabled"
        @click="confirmationPopupwithReason(getShortliststatus.status)"
      >
        Shortlist</CButton
      >
      <CButton
        color="primary"
        variant="outline"
        class="mx-2 action-btn"
        @click="navigateToCandidate(item)"
      >
        More Details</CButton
      >
    </div>
    <PreviewModal
      :modalTitle="previewModal.modalTitle"
      :modalColor="previewModal.modalColor"
      :isShowPopup="previewModal.isShowPopup"
      :buttons="previewModal.buttons"
      :modalCallBack="modalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :size="previewModal.size"
      :closeOnBackdrop="previewModal.closeOnBackdrop"
    />
    <Modal
      :modalTitle="noCVModal.modalTitle"
      :modalColor="noCVModal.modalColor"
      :modalContent="noCVModal.modalContent"
      :isShowPopup="noCVModal.isShowPopup"
      :buttons="noCVModal.buttons"
      :modalCallBack="noCVModalCallback"
      :closeOnBackdrop="false"
      :stepperModal="noCVModal.stepperModal"
    />
    <PopupModal
      v-if="popupModal.isShowPopup"
      :modalTitle="popupModal.modalTitle"
      :modalColor="popupModal.modalColor"
      :modalContent="popupModal.modalContent"
      :modalLabel="popupModal.modalLabel"
      :isShowPopup="popupModal.isShowPopup"
      :popupModalCallBack="popupModalCallBack"
      :candidateItem="popupModal.candidateItem"
      :modalJob="popupModal.modalJob"
      :modalReasonType="popupModal.modalTitle"
      :isCommentRequired="false"
      :size="`lg`"
    />
  </CCard>
</template>

<script>
import CandidateInfo from "@/containers/CandidateList/CandidateInfo";
import m from "moment";
import { mapGetters, mapActions } from "vuex";
import { getScope, Role, appendAccessToken } from "@/helpers/helper";
import PreviewModal from "@/components/reusable/PreviewModal";
import pdf from "vue-pdf";
import Modal from "@/components/reusable/Modal";
import PopupModal from "@/components/reusable/PopupModal";
import { BASE_URL } from "@/service_urls";

export default {
  name: "CandidateListForShortlistProcess",
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      previewModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["OK"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      noCVModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        modalContent: "There is No CV / Resume Available for this Candidate",
        isShowPopup: false,
        buttons: ["OK"],
        stepperModal: true,
      },
      popupModal: {
        modalColor: "",
        modalTitle: "",
        modalContent: "",
        modalLabel: "",
        isShowPopup: false,
        candidateItem: {},
        modalJob: "",
      },
      wrapMaxLength: 27,
      rejectCandidate: null,
    };
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
    job: {
      type: Object,
      default: {},
    },
    doh_candidate_status: {
      type: Object,
      default: {},
    },
    application_status: {
      type: Object,
      default: null,
    },
    showJobExpiry: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CandidateInfo,
    PreviewModal,
    Modal,
    PopupModal,
  },
  computed: {
    ...mapGetters([
      "getUserId",
      "getCustomerId",
      "getShortliststatus",
      "getInterviewstatus",
      "getReleasestatus",
      "getRecruitstatus",
      // "getFacilityConfig",
      "getOrgID",
      "currentPageSchema",
    ]),
    isCustomer() {
      return this.currentUserRole === this.Role.customerAdmin ||
        this.currentUserRole === this.Role.customerRecruiter
        ? true
        : false;
    },
    pqrLevels() {
      if (Array.isArray(this.item.pqr_levels) && this.item.pqr_levels.length) {
        const pqr_levels = this.item.pqr_levels.filter(
          ({ potential }) => !potential
        );
        return pqr_levels.length
          ? pqr_levels[0]?.pqr_detail?.level?.candidate_level ||
              pqr_levels[0]?.pqr_ahp_detail?.description ||
              "--"
          : "--";
      }
      return this.item.level_name;
    },
    candidateInfoData() {
      let {
        first_name,
        surname,
        email,
        gender: { gender },
        title: { title },
        speciality: { speciality },
        sub_speciality: { sub_speciality },
        candidate_level: { candidate_level },
        candidate_type: { candidate_type },
        nationality,
        location: { location },
        dob,
        age,
        main_qualification: { qualification },
        main_qualification_year,
        phone,
        eligibility_letter_available_for,
        local_license,
        dataflow_complete,
        candidate_uid,
        whatsapp,
        job_type,
        job_time,
        flexible,
        extra_hours_available,
        retired_clinician,
        last_date_of_practise,
        volunteer,
        preferred_locations,
        profile_picture,
        job_reference,
        highest_qualification_name,
        highest_qualification_acronym,
        last_date_of_login,
      } = Object.assign(this.item, {
        title: this.item.title || {},
        speciality: this.item.speciality || {},
        sub_speciality: this.item.sub_speciality || {},
        candidate_level: this.item.candidate_level || {},
        nationality: this.item.nationality_names?.join(),
        main_qualification: this.item.main_qualification || {},
        gender: this.item.gender || { gender: "" },
        location: this.item.location || {},
        candidate_uid: this.item.candidate_uid || null,
        whatsapp: this.item.whatsapp || "",
        available: this.item.available || null,
        local_license: this.item.local_license,
        dataflow_complete: this.item.dataflow_complete,
        flexible: this.item.flexible,
        extra_hours_available: this.item.extra_hours_available || "--",
        retired_clinician: this.checkData(this.item.retired_clinician),
        volunteer: this.checkData(this.item.volunteer),
        highest_qualification_name:
          this.item.highest_qualification_name || null,
        highest_qualification_acronym:
          this.item.highest_qualification_acronym || null,
        last_date_of_login: this.item.last_date_of_login,
      });

      var availability_summary = [];
      if (job_type) {
        availability_summary.push(job_type);
      }
      if (job_time) {
        availability_summary.push(job_time);
      }
      if (
        this.item.preferred_locations &&
        this.item.preferred_locations.length
      ) {
        preferred_locations = this.item.preferred_locations
          .map((value) => value.location?.comments)
          .join(", ");
      } else preferred_locations = "--";

      eligibility_letter_available_for = eligibility_letter_available_for
        ? eligibility_letter_available_for.split(",").join(", ")
        : "--";
      if (this.doh_candidate_status?.job_id) {
        job_reference = `${this.doh_candidate_status?.job_title} |
        ${this.doh_candidate_status?.customer_id} - ${this.doh_candidate_status?.job_id}`;
      } else job_reference = "--";
      return {
        jobStatus: this.jobStatus,
        first_name,
        surname,
        gender,
        title,
        speciality,
        sub_speciality,
        candidate_level,
        candidate_type,
        nationality,
        dob,
        email,
        age,
        qualification,
        main_qualification_year,
        phone,
        whatsapp,
        eligibility_letter_available_for,
        local_license,
        dataflow_complete,
        flexible,
        candidate_uid,
        availability_summary,
        extra_hours_available,
        location,
        retired_clinician,
        last_date_of_practise,
        volunteer,
        preferred_locations,
        profile_picture,
        job_reference,
        highest_qualification_name,
        highest_qualification_acronym,
        last_date_of_login,
      };
    },
    specialisation() {
      let specialisation_data = null;
      this.item.qualifications.some((e) => {
        if (e.on_specialist_register) {
          specialisation_data = e;
          return true;
        }
        return false;
      });
      return specialisation_data;
    },
    splqualification_classification_name() {
      return (
        this.specialisation?.qualification?.qualification_classification_name ||
        null
      );
    },
    splqualification_country_name() {
      return this.specialisation?.country_name || null;
    },
    highestQualification() {
      let highestQualification = null;
      this.item.qualifications &&
        this.item.qualifications.length &&
        this.item.qualifications.some((e) => {
          if (e.main) {
            highestQualification = e;
            return true;
          }
          return false;
        });
      return highestQualification;
    },
    qualification() {
      return this.highestQualification?.qualification?.acronym || null;
    },
    qualificationCountry() {
      return this.highestQualification?.country_name || null;
    },
    qualificationDate() {
      let issued_month = this.highestQualification?.month_awarded || null;
      let issue_year = this.highestQualification?.year_awarded || null;
      if (issued_month || issue_year)
        return `${
          issued_month ? m.monthsShort()[issued_month - 1] : null
        } ${issue_year}`;
      return null;
    },
    cvDocument() {
      let _base_domain = `${window.location.origin}/api/v1`;
      if (this.item.cv_doc_uuid && this.item.cv_ext)
        return {
          link: appendAccessToken(
            `${_base_domain}/customer/${this.getCustomerId}/file/${this.item.cv_doc_uuid}`
          ),
          document_ext: this.item.cv_ext,
        };
      return false;
    },
    isNurse() {
      return this.item?.candidate_type_id
        ? this.item?.candidate_type_id === 2
        : false;
    },
    isAHP() {
      return this.item?.candidate_type_id
        ? this.item?.candidate_type_id === 3
        : false;
    },
    localLicense() {
      return this.item?.eligibility_letter_available_for !== null
        ? this.item?.eligibility_letter_available_for
        : "N/A";
    },
    otherSpecialities() {
      return (
        _(this.item?.candidate_specialities)
          .filter((speciality) => {
            return (
              speciality.main != true &&
              !speciality.other_special_interest &&
              !speciality.special_interest
            );
          })
          .map((speciality) => {
            return speciality.speciality
              ? speciality.speciality?.speciality
              : speciality?.other_speciality;
          })
          .value()
          .join(", ") || "--"
      );
    },
    expirydays() {
      return this.doh_candidate_status.days_to_expire;
    },
    qualificationName() {
      if (
        this.item?.qualifications &&
        this.item?.highest_qualification_name &&
        this.item?.highest_qualification_name == "Other - not listed"
      ) {
        return _(this.item?.qualifications)
          .filter((qualification) => {
            return (
              qualification?.qualification_name ==
              this.item?.highest_qualification_name
            );
          })
          .map((qualification) => {
            return qualification?.other_qualification_name
              ? "Other - " + qualification?.other_qualification_name
              : null;
          })
          .value()
          .toString();
      } else {
        return this.item?.highest_qualification_acronym
          ? this.item?.highest_qualification_acronym
          : this.item?.highest_qualification_name;
      }
    },
    shortlistDisabled() {
      return this.application_status?.withdrawal_on &&
        this.application_status?.withdrawal_by
        ? true
        : false;
    },
  },
  methods: {
    ...mapActions([
      "setCandidate",
      "setSelectedcandidateById",
      "setCandidateListBackUrl",
      "createshortlist",
      "releaseCandidate",
      "resetCandidateDetail",
      "createInterviewlist",
      "createRecruitList",
      "fetchAllJobsByShortlistProcess",
      "showToast",
      "disapproveAppliedCandidate",
    ]),
    isWrapped(data) {
      return data?.length > this.wrapMaxLength || false;
    },
    navigateToCandidate(item) {
      this.setCandidateListBackUrl(this.$route.fullPath);
      this.resetCandidateDetail();
      this.$router.push({ path: `/candidate/${item.candidate_uuid}` });
    },
    confirmationForReject() {
      this.rejectCandidate = {
        candidate_uid: this.item?.candidate_uid,
        job_id: this.job?.job_id,
        application_id: this.application_status?.application_id,
      };
      this.popupModal.modalLabel = "";
      this.popupModal.modalColor = "danger";
      this.popupModal.modalContent =
        "Please confirm this candidate is disapproved from the recruitment process";
      this.popupModal.modalTitle = "Disapprove Candidate";
      this.popupModal.isShowPopup = true;
    },
    confirmationPopupwithReason(type) {
      this.popupModal.modalLabel = "";
      if (type === this.getReleasestatus.status) {
        this.popupModal.modalColor = "danger";
        this.popupModal.modalLabel = "Select Reason Code";
        this.popupModal.modalContent =
          "Please confirm you are withdrawing this candidate from the recruitment process ?";
      } else {
        if (type === this.getShortliststatus.status) {
          this.popupModal.modalContent =
            "Please confirm would you like to shortlist this candidate for review/interview ?";
          this.popupModal.modalJob = "Select Job";
          this.fetchAllJobsByShortlistProcess(
            this.candidateInfoData?.candidate_uid
          );
        } else if (type === this.getInterviewstatus.status) {
          this.popupModal.modalContent =
            "Please confirm an interview is being scheduled with this candidate";
        } else if (type === this.getRecruitstatus.status) {
          this.popupModal.modalContent =
            "Please confirm the interview has been completed and you are recruiting this candidate. Alternatively please remove the candidate from your list by selecting the release button";
        }
        this.popupModal.modalColor = "primary";
      }
      this.popupModal.modalTitle = type;
      this.popupModal.isShowPopup = true;
      this.popupModal.candidateItem = {
        candidate_id: this.item.candidate_uid,
        doh_candidate_status: this.doh_candidate_status
          ? this.doh_candidate_status
          : null,
        shortlistStatus: this.getShortliststatus,
        interviewStatus: this.getInterviewstatus,
        recruitStatus: this.getRecruitstatus,
        releaseStatus: this.getReleasestatus,
      };
    },
    checkData(value) {
      if (value !== null) {
        return value === "Yes" || value === true ? "Yes" : "No";
      }
      return "--";
    },
    navigateToJobList({ job_id }) {
      this.$router.push({ path: `/jobs-list/?job_id=${job_id}` });
    },
    getDtSpl(qualification) {
      if (qualification != null) {
        let month_num = qualification.specialist_registration_month || null;
        let month_str = null;
        if (month_num) month_str = m.monthsShort()[month_num - 1];
        return `${month_str} ${qualification.specialist_registration_year}`;
      }
      return null;
    },
    previewCV(data) {
      if (data) {
        this.preview(data);
      } else {
        this.noCVModal.isShowPopup = true;
      }
    },
    preview(data) {
      if (data?.link) this.iframe.url = data.link;
      else this.iframe.url = null;
      this.iframe.document_ext = data.document_ext.toLowerCase();
      if (["PNG", "JPG", "JPEG", "GIF"].includes(data.document_ext)) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = data.link;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (data.document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(data.link);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (
        ["TXT", "EPS", "TIF", "TIFF", "RAW"].includes(data.document_ext)
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          data.link +
          "";
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    noCVModalCallback(action) {
      this.noCVModal.isShowPopup = false;
    },
    modalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    popupModalCallBack(action, payload, candidateItem) {
      if (action == "Confirm" && this.rejectCandidate?.application_id) {
        this.disapproveAppliedCandidate(this.rejectCandidate);
        this.popupModal.isShowPopup = false;
        this.rejectCandidate = null;
      } else if (action === "Confirm") {
        let shortlistPayload = {
          candidate_id: candidateItem.candidate_id,
          reason_id: payload.reason_id || null,
          comments: payload.comments || "",
          application_id: this.application_status?.application_id || null,
        };
        const { modalTitle } = this.popupModal;
        const {
          shortlistStatus: { status: shortlistStatus },
          interviewStatus: { status: interviewStatus },
          recruitStatus: { status: recruitStatus },
          releaseStatus: { status: releaseStatus },
        } = candidateItem;

        if (modalTitle === shortlistStatus) {
          shortlistPayload.job_id = payload.job_id;
          this.createshortlist(shortlistPayload);
        } else if (modalTitle === interviewStatus) {
          shortlistPayload.candidate_status_id =
            candidateItem.doh_candidate_status.candidate_status_id;
          shortlistPayload.status_id = candidateItem.interviewStatus.status_id;
          this.createInterviewlist(shortlistPayload);
        } else if (modalTitle === recruitStatus) {
          shortlistPayload.candidate_status_id =
            candidateItem.doh_candidate_status.candidate_status_id;
          shortlistPayload.status_id = candidateItem.recruitStatus.status_id;
          this.createRecruitList(shortlistPayload);
        } else if (modalTitle === releaseStatus) {
          shortlistPayload.candidate_status_id =
            candidateItem.doh_candidate_status.candidate_status_id;
          shortlistPayload.status_id = candidateItem.releaseStatus.status_id;
          if (
            candidateItem.doh_candidate_status.status_id ===
            candidateItem.shortlistStatus.status_id
          ) {
            shortlistPayload.releasefrom =
              candidateItem.shortlistStatus.status_id;
          } else if (
            candidateItem.doh_candidate_status.status_id ===
            candidateItem.interviewStatus.status_id
          ) {
            shortlistPayload.releasefrom =
              candidateItem.interviewStatus.status_id;
          } else if (
            candidateItem.doh_candidate_status.status_id ===
            candidateItem.recruitStatus.status_id
          ) {
            shortlistPayload.releasefrom =
              candidateItem.recruitStatus.status_id;
          }
          this.releaseCandidate(shortlistPayload);
        }
        this.popupModal.isShowPopup = false;
      } else if (action === "Cancel" || action === false) {
        this.popupModal.isShowPopup = false;
        this.rejectCandidate = null;
      }
    },
  },
  filters: {
    m: function (date) {
      return m(date).format("MMM YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
.btnmargin {
  margin-right: 15px;
  padding-top: 0.1rem;
  padding-right: 0.5rem;
  padding-bottom: 0.05rem;
  padding-left: 0.5rem;
  font-size: 12px;
}
.btncol {
  text-align: center;
  vertical-align: middle;
}
.lblspan {
  font-weight: bold;
}
.flex-1 {
  font-size: 13.5px;
}
.flex-2 {
  font-size: 13.5px;
}
.add-details-btn {
  max-width: 200px;
}
.icon-wrapper {
  .icon {
    width: 50px;
    img {
      width: 35px;
      padding: 5px;
      text-align: center;
      cursor: pointer;
    }
    img.disabled {
      // pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5 !important;
    }
  }
}
.border-top-primary {
  border-top: 1px solid #d8dbe0 !important;
  border-color: #dd3651 !important;
}
.left-side-width {
  width: 40%;
}
.right-side-width {
  width: 70%;
}
.action-btn {
  width: 100%;
  font-size: 11px;
}
.btn:disabled {
  cursor: not-allowed;
}
</style>
