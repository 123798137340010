var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"col-sm-12 job-candidate-status border-light"},[_c('h5',{staticClass:"text-primary text-center mt-3"},[_vm._v("Candidates Status")]),_c('CRow',{staticClass:"mt-3"},[_c('CCol',{staticClass:"mb-3",attrs:{"md":"3"}},[_c('ShortlistCount',{attrs:{"activeJobs":_vm.getActiveJobCount,"totalCount":_vm.getTotalCount,"expiryJobCount":_vm.getExpiryJobCount,"status":_vm.filters.status}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('CRow',{staticClass:"row pb-3 adjust-filter-label"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Jobs")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 pr-1"},[_c('Select',{attrs:{"name":"job","value":_vm.filters.job,"options":_vm.options && _vm.options['job'] ? _vm.options['job'] : [],"taggable":false,"multiple":false,"clearable":false},on:{"input":_vm.handleChangeSelect}})],1)])],1),_c('CCol',{attrs:{"md":"3","lg":"2"}},[_c('CRow',{staticClass:"row pb-3 adjust-filter-label"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Status")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 pr-1"},[_c('Select',{attrs:{"name":"status","value":_vm.filters.status,"options":_vm.options && _vm.options['status'] ? _vm.options['status'] : [],"taggable":false,"multiple":false,"clearable":false},on:{"input":_vm.handleChangeSelect}})],1)])],1),_c('CCol',{attrs:{"md":"3","lg":"2"}},[_c('CRow',{staticClass:"row mb-3 adjust-filter-label"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Candidate Type")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 pr-1"},[_c('Select',{attrs:{"name":"candidateType","value":_vm.filters.candidateType,"options":_vm.options && _vm.options['candidateType']
                  ? _vm.options['candidateType']
                  : [],"taggable":false,"multiple":false,"clearable":true},on:{"input":_vm.handleChangeSelect}})],1)])],1),_c('CCol',{attrs:{"md":"3","lg":"2"}},[_c('CRow',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Candidates Linked to"),_c('br'),_vm._v(" Expiring Jobs")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 pr-1"},[_c('Select',{attrs:{"name":"expiryBy","value":_vm.filters.expiryBy,"options":_vm.options && _vm.options['expiryBy'] ? _vm.options['expiryBy'] : [],"taggable":false,"multiple":false,"clearable":true},on:{"input":_vm.handleChangeSelect}})],1)])],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"3"}}),_c('CCol',[_c('CRow',{staticClass:"ml-1"},[_c('h5',[_vm._v("Search Results: "+_vm._s(_vm.filteredStatusCandidatesCount))])])],1)],1),_c('hr',{staticClass:"mb-4"}),_c('CRow',{staticClass:"mb-3"},_vm._l((_vm.getCandidates),function(item,index){return _c('CCol',{key:index,attrs:{"sm":"6","md":"3"}},[(_vm.isFetchingStatusCandidates)?_c('div',{staticClass:"skeleton-card"}):_c('candidate-list-for-shortlist-process',{attrs:{"item":item.candidate,"job":item.job,"doh_candidate_status":item.doh_candidate_status,"application_status":item.application_status,"showJobExpiry":!_vm.disableExpiringIn}})],1)}),1),(
        !_vm.getFilteredStatusCandidates.length && !_vm.isFetchingStatusCandidates
      )?_c('div',{staticClass:"d-flex justify-content-center p-4"},[_c('h1',[_vm._v("No Data found!")])]):_vm._e(),(
        _vm.getFilteredStatusCandidates.length &&
        _vm.jobCandidatesPagePagination.noMoreCandidates
      )?_c('div',{staticClass:"d-flex justify-content-center"},[_c('span',{staticClass:"btn-pill btn-primary p-2"},[_vm._v("No more records to display!")])]):_vm._e(),(_vm.isFetchingStatusCandidates)?_c('CRow',{staticClass:"d-flex justify-content-around align-items-center",staticStyle:{"height":"200px"}},[_c('CCol',[_c('div',{staticClass:"has-loading-overlay"},[_c('LoadingOverlay')],1)])],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }